import React from "react";
import CountryPage from "../../components/country-page";

const ANDROID_BTN_CONFIG = {
  os: "android",
  href:
    "https://play.google.com/store/apps/details?id=com.letiagoalves.radiotuga.germany",
  target: "_blank",
  text: "Download para Android",
  bgColor: "#FFCE00",
  fgColor: "#000000",
};

export default () => (
  <CountryPage
    countryCode="de"
    appName="Radio Deutschland"
    androidBtnConfig={ANDROID_BTN_CONFIG}
  />
);
